const CATALOG_VALUE_CHANGED = 'catalog/CATALOG_VALUE_CHANGED'
const TOGGLE_STORE_FILTERS = 'catalog/TOGGLE_STORE_FILTERS'
const CONTENT_VALUE_CHANGED = 'catalog/CONTENT_VALUE_CHANGED'
const CAMPAIGN_VALUE_CHANGED = 'catalog/CAMPAIGN_VALUE_CHANGED'


const initialState = {
    content: {
        "banner": [],
        "inboxMessage": [],
        "inboxOffer": [],
        "pastOffer": [],
        "detail": []
    },
    rioCampaigns: [],
    settings: [],
    checkFormVersions: {
        "terms": true,
        "personalconsentform": true,
        "personaldisc": true
    },
    products: [],
    productDetail: null,
    mainCategories: [],
    subCategories: [],
    stores: [],
    selectedCategory: "",
    storeSearchTerm: "",
    storeFilters: [],
    storeFilterKeys: [], //rbs'den

    sub1Categories: [],
    sub2Categories: [],
}

export default (state = initialState, action) => {
    switch (action.type) {

        case CATALOG_VALUE_CHANGED: {
            return {
                ...state,
                [action.key]: action.value
            }
        }

        case CONTENT_VALUE_CHANGED: {
            return {
                ...state,
                content: {
                    ...state.content,
                    [action.key]: action.value
                }
            }
        }

        case CAMPAIGN_VALUE_CHANGED: {
            return {
                ...state,
                [action.key]: action.value
            }
        }

        case TOGGLE_STORE_FILTERS: {
            let filterId = action.filterId
            let storeFilters = [...state.storeFilters]
            if (storeFilters.indexOf(filterId) < 0) {
                storeFilters.push(filterId)
            } else {
                storeFilters = storeFilters.filter(f => f != filterId)
            }
            return {
                ...state,
                storeFilters
            }
        }
        default:
            return state
    }
}

/////////////functions/////////////////////////

const catalogValueChanged = (key, value) => {
    return {
        type: CATALOG_VALUE_CHANGED,
        key,
        value
    }
}

const toggleStoreFilters = (filterId) => {
    return {
        type: TOGGLE_STORE_FILTERS,
        filterId
    }
}

const contentValueChanged = (key, value) => {
    return {
        type: CONTENT_VALUE_CHANGED,
        key,
        value
    }
}

const rioCampaignValueChanged = (key, value) => {
    return {
        type: CAMPAIGN_VALUE_CHANGED,
        key,
        value
    }
}

export {
    catalogValueChanged,
    toggleStoreFilters,
    contentValueChanged,
    rioCampaignValueChanged
}