import { getWebsiteBanners } from "../helpers/rioHelper"

const APP_VALUE_CHANGED = 'app/APP_VALUE_CHANGED'
const SHOW_MODAL_WITH_TEXT = 'app/SHOW_MODAL_WITH_TEXT'
const SHOW_POPUP = 'app/SHOW_POPUP'
const SET_POPUP_CONTENT = 'app/SET_POPUP_CONTENT'
const SHOW_TERM_MODAL = 'app/SHOW_TERM_MODAL'

const initialState = {
    appName: "Starbucks",
    apiResponse: "",
    showLoading: false,
    showSimpleModal: false,
    simpleModalText: "",
    showPopup: false,
    popupContent: null,
    showTermModal: false,
    termUrl: null
}

export default (state = initialState, action) => {
    switch (action.type) {

        case APP_VALUE_CHANGED: {
            return {
                ...state,
                [action.key]: action.value
            }
        }
        case SHOW_MODAL_WITH_TEXT: {
            return {
                ...state,
                showSimpleModal: true,
                simpleModalText: action.text,
                showLoading: false,
            }
        }
        case SHOW_POPUP: {
            return {
                ...state,
                showPopup: action.value,
            }
        }
        case SET_POPUP_CONTENT: {
            return {
                ...state,
                popupContent: action.content,
            };
        }

        case SHOW_TERM_MODAL: {
            return {
                ...state,
                showTermModal: action.value,
                termUrl: action.url
            }
        }

        default:
            return state
    }
}

const appValueChanged = (key, value) => {
    return {
        type: APP_VALUE_CHANGED,
        key,
        value
    }
}

const showModalWithText = (text) => {
    return {
        type: SHOW_MODAL_WITH_TEXT,
        text
    }
}
const setShowPopup = (value) => {
    return {
        type: SHOW_POPUP,
        value
    }
}

const setPopupContent = (content) => {
    return {
        type: SET_POPUP_CONTENT,
        content,
    };
};

const fetchWebsiteBanner = () => {
    return async (dispatch) => {
        try {
            let resp = await getWebsiteBanners();
            let richContent = resp.data.result;
            dispatch(setPopupContent(richContent));
        } catch (error) {
            console.error("fetchWebsiteBanner error:", error);
        }
    };
};

const setShowTermModal = (value, url) => {
    return {
        type: SHOW_TERM_MODAL,
        url,
        value
    }
}

export {
    appValueChanged,
    showModalWithText,
    setShowPopup,
    setPopupContent,
    fetchWebsiteBanner,
    setShowTermModal
}