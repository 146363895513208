const initialState = {
    user: null,
    //orders: [],
    activities:[],
    lastActivity:null,
    lastPendingOrder:null,
    selectedPaymentMethod: null,
    masterpassCardsList: [],
    partitionSha256: "",
    lat: 41.028316,
    lon: 29.036865
}
const VALUE_CHANGED = 'user/VALUE_CHANGED'

export default (state = initialState, action) => {
    switch (action.type) {

        case VALUE_CHANGED: {
            return {
                ...state,
                [action.key]: action.value
            }
        }

        default:
            return state
    }
}

const valueChanged = (key, value) => {
    return {
        type: VALUE_CHANGED,
        key,
        value
    }
}
export {
    valueChanged,
}