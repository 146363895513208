import {createStore,applyMiddleware,combineReducers} from 'redux'
import {composeWithDevTools} from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
import app from './redux/app'
import loggerMiddleware from './redux/loggerMiddleware'
import authMiddleware from './redux/authMiddleware'
import auth from './redux/auth'
import user from './redux/user'
import cart from './redux/cart'
import catalog from './redux/catalog'

const reducer = combineReducers({
  app,
  auth,
  user,
  catalog,
  cart,
})

export function initializeStore(initialState = {}) {
  return createStore(reducer, initialState, composeWithDevTools(applyMiddleware(thunkMiddleware,errorHandlerMiddleware, loggerMiddleware , authMiddleware)))
}

function errorHandlerMiddleware({ getState }) {
  return next => action => {
    //console.log('will dispatch', action)
    // Call the next dispatch method in the middleware chain.
    const returnValue = next(action)

    //console.log('state after dispatch', getState())

    // This will likely be the action itself, unless
    // a middleware further in chain changed it.
    return returnValue
  }
}
