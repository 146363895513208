import React from 'react'
//import './styles.css'
import {Icon} from '../../components/icon'
import caretDown from '../../lib/icons/caretDown'
import close from '../../lib/icons/close'
import check from '../../lib/icons/check'
import warningSolid from '../../lib/icons/warningSolid'
import ThemeContext from '../../components/theme-context'
import InputMask from "react-input-mask"


class Field extends React.Component {
    static contextType = ThemeContext
    constructor(props){
        super(props);
        this.state = {
            focused: false,
            filled: false
        };
    }
    onFocus = () => {
        this.setState({focused: true})
    }
    onBlur = () => {
        if(this.props.onBlur){
            this.props.onBlur()
        }
        this.setState({focused: false})
        if(this.props.onFocusLoose){
            this.props.onFocusLoose()
        }
    }
    onChange = (event) => {
        if(event.target.value) {
            this.setState({filled: true})
        } else {
            this.setState({filled: false})
        }
        this.props.valueChanged(event.target.value,event)
    }
    onkeydown = (event)=>{
        if(this.props.onkeydown){
            this.props.onkeydown(event)
        }
    }
    render() {
        if(this.props.error) {
            return (
                    <div className={'field ' + (this.props.className ? this.props.className : '') + (this.state.focused || this.props.value ? ' field--isFocused' : '') + (this.props.error ? ' field--isInvalid' : '')}>
                        <div className="field__inputWrapper flex">
                            <label className={'floatLabel ' + (this.context.primary === 'dark' ? 'color-textWhiteSoft ' : 'color-textBlackSoft ') + (this.state.focused || this.state.filled || this.props.value ? 'floatLabel--isActive' : '')} htmlFor={this.props.id}>{this.props.children}</label>
                            <input onKeyDown={this.onkeydown} value={this.props.value} type={this.props.type=="password"?"password":"text"} onFocus={this.onFocus} onBlur={this.onBlur} onChange={this.onChange} aria-invalid="false" className="fieldInput" id={this.props.id} autoComplete={(this.props.autocomplete ? 'on' : 'off')}/>
                            <Icon className='valign-middle field__warningIcon self-end flex-shrink-none mb1' path={ warningSolid } size='20px' />
                        </div>
                        <div>
                            <div>
                            <span className="block pt2 " id="fieldOne-validationHint">
                                <span className="fieldStatus fieldStatus--error">
                                <Icon className='valign-middle fieldStatus__icon' path={ close } size='20px' />
                                <span className="fieldStatus__text">{this.props.errorMessage}</span>
                                </span>
                            </span>
                            </div>
                        </div>
                    </div>
            )
        } else {
            return (
                    <div className={'field ' + (this.context.primary === 'dark' ? 'field--dark ' : '') + (this.props.className ? this.props.className : '') + (this.state.focused || this.props.value ? ' field--isFocused' : '') + (this.props.error ? ' field--isInvalid' : '')}>
                        <div className={'field__inputWrapper flex'}>
                            <label className={'floatLabel ' + (this.context.primary === 'dark' ? 'color-textWhiteSoft ' : 'color-textBlackSoft ') + (this.state.focused || this.state.filled || this.props.value ? 'floatLabel--isActive' : '')} htmlFor={this.props.id}>{this.props.children}</label>
                            <input onKeyDown={this.onkeydown} tabIndex="11" disabled={this.props.disable} value={this.props.value} type={this.props.type=="password"?"password":this.props.type=="tel"?"tel":"text"} onFocus={this.onFocus} onBlur={this.onBlur} onChange={this.onChange} aria-invalid="false" className="fieldInput" id={this.props.id} autoComplete={(this.props.autocomplete ? 'on' : 'off')}/>
                        </div>
                    </div>

            )
        }

    }
}

class FieldMask extends React.Component {
    static contextType = ThemeContext
    constructor(props){
        super(props);
        this.state = {
            focused: false,
            filled: false
        };
    }
    onFocus = () => {
        this.setState({focused: true})
    }
    onBlur = () => {
        this.setState({focused: false})
        if(this.props.onFocusLoose){
            this.props.onFocusLoose()
        }
    }
    onChange = (event) => {
        if(event.target.value) {
            this.setState({filled: true})
        } else {
            this.setState({filled: false})
        }
        this.props.valueChanged(event.target.value,event)
    }
    onkeydown = (event)=>{
        if(this.props.onkeydown){
            this.props.onkeydown(event)
        }
    }
    render() {
        return(
        <div className={'field ' + (this.context.primary === 'dark' ? 'field--dark ' : '') + (this.props.className ? this.props.className : '') + (this.state.focused || this.props.value ? ' field--isFocused' : '') + (this.props.error ? ' field--isInvalid' : '')}>
            <div className={'field__inputWrapper flex'}>
                <label className={'floatLabel ' + (this.context.primary === 'dark' ? 'color-textWhiteSoft ' : 'color-textBlackSoft ') + (this.state.focused || this.state.filled || this.props.value ? 'floatLabel--isActive' : '')} htmlFor={this.props.id}>{this.props.children}</label>
                <InputMask autoFocus={this.props.autoFocus} mask="9999-9999-9999-9999" onChange={(ev)=>{this.onChange(ev)}} value={this.props.value} className="fieldInput"/>
            </div>
        </div>
        )
        return(
            <InputMask mask="9999-9999-9999-9999" onChange={(ev)=>{this.onChange(ev)}} value={this.props.value}/>
        )
            return (
                    <div className={'field ' + (this.context.primary === 'dark' ? 'field--dark ' : '') + (this.props.className ? this.props.className : '') + (this.state.focused || this.props.value ? ' field--isFocused' : '') + (this.props.error ? ' field--isInvalid' : '')}>
                        <div className={'field__inputWrapper flex'}>
                            <label className={'floatLabel ' + (this.context.primary === 'dark' ? 'color-textWhiteSoft ' : 'color-textBlackSoft ') + (this.state.focused || this.state.filled || this.props.value ? 'floatLabel--isActive' : '')} htmlFor={this.props.id}>{this.props.children}</label>
                            <input onKeyDown={this.onkeydown} tabIndex="0" disabled={this.props.disable} value={this.props.value} type={this.props.type=="password"?"password":"text"} onFocus={this.onFocus} onBlur={this.onBlur} onChange={this.onChange} aria-invalid="false" className="fieldInput" id={this.props.id} autoComplete={(this.props.autocomplete ? 'on' : 'off')}/>
                        </div>
                    </div>

            )
        }
}


class FieldStatus extends React.Component {
    render() {
        if(this.props.error) {
            return(
                <span className="fieldStatus fieldStatus--error">
                    <Icon className='valign-middle fieldStatus__icon' path={ close } size='20px' />
                    <span className="fieldStatus__text">{this.props.children}</span>
                </span>
            )
        }
    }
}

class FormContainer extends React.Component {
    render() {
        const children = React.Children.map(this.props.children, child => {
            return React.cloneElement(child, {
                fields: this.props.fields
            });
        });
        return(
            <div>
                { children }
            </div>
        )
    }
}

class EditField extends React.Component {
    render() {
        return(
            <div className="sb-editField flex justify-between items-end mb9" bis_skin_checked="1"><span
                className="floatLabel color-textBlackSoft sb-editField__label">{this.props.children}</span>
                <ul className="sb-editField__list pr2 flex-auto " data-e2e="myTest"></ul>
                <button onClick={()=>{this.props.onClick()}} className="sb-editField__button text-bold">{this.props.editText}</button>
            </div>
        )
    }
}

class Select extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            value: this.props.value,
            error: this.props.error,
            autocomplete: this.props.autocomplete,
        };
    }
    onChange = (event) => {
        //console.log(" onChange event.target.value is",event.target.value)
        this.setState({selected: true})
        this.setState({value: event.target.value})
    }
    render() {
        const selectedText = () => {
            if(this.state.value) {
                return this.state.value
            } else {
                return (
                    <span>&nbsp;</span>
                )
            }
        }
        const errorField = () => {
            if(this.state.error) {
                return (
                    <span className="block pt2 " id="month-validationHint"><span
                        className="fieldStatus fieldStatus--error">
                        <Icon className='valign-middle fieldStatus__icon' path={ close } size='20px' />
                        <span
                        className="fieldStatus__text">Please select a month other than March.</span></span></span>
                )
            } else {
                return(
                    <span></span>
                )
            }
        }
        return (
            <div>
            <div className={'select '  + (this.props.className ? this.props.className : '')}><label
                 className={'floatLabel color-textBlackSoft select__floatLabel ' + (this.state.value ? 'floatLabel--isActive' : '')} htmlFor={this.props.id}>{this.props.label}</label>
                  <select onChange={(e)=>{this.props.onChange(e.target.value);this.onChange(e)}} aria-invalid="false" className="select__input" id={this.props.id} value={this.state.value}>
               {/*  <select onChange={(this.props.onChange ? this.props.onChange : this.onChange)} aria-invalid="false" className="select__input" id={this.props.id} value={this.state.value}> */}
                    <option className="hidden" hidden="" disabled="" value="">{this.props.label}</option>
                    {this.props.children}
                 </select>
                 <span aria-hidden="true" className={'select__selectedText '  + (this.state.error ? 'select__selectedText--isInvalid ' : '')}>
                   {/* {selectedText()} */}
                    {this.props.selectedText}
                    <Icon className='valign-middle select__icon' path={ caretDown } size='28px' />
                </span>
            </div>{errorField()}
            </div>
        )
    }
}

class Select2 extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            value: this.props.value,
            error: this.props.error,
            autocomplete: this.props.autocomplete,
        }
    }
    onChange = (event) => {
        //console.log(" onChange event.target.value is",event.target.value)
        this.setState({selected: true})
        this.setState({value: event.target.value})
    }
    render() {
        const selectedText = () => {
        
        return this.props.selectedText?
                <span>{this.props.selectedText}</span>:
                <span>{this.props.value}</span>
        }
        return (
            <div>
                <div className={'select '  + (this.props.className ? this.props.className : '')}>
                    {/* <label className={'floatLabel color-textBlackSoft select__floatLabel ' + (this.state.value ? 'floatLabel--isActive' : '')} htmlFor={this.props.id}>{this.props.label}</label> */}
                    <label className={'floatLabel color-textBlackSoft select__floatLabel ' + (this.props.value ? 'floatLabel--isActive' : '')} htmlFor={this.props.id}>{this.props.label}</label>
                    {/*  <select onChange={(e)=>{this.props.onChange(e.target.value);this.onChange(e)}} aria-invalid="false" className="select__input" id={this.props.id} value={this.state.value}> */}
                    <select onChange={(this.props.onChange ? this.props.onChange : this.onChange)} aria-invalid="false" className="select__input" id={this.props.id} value={this.props.value}>
                        <option className="hidden" hidden="" disabled="" value="">{this.props.label}</option>
                        {this.props.children}
                    </select>
                    <span aria-hidden="true" className={'select__selectedText '  + (this.state.error ? 'select__selectedText--isInvalid ' : '')}>
                    {selectedText()}
                    {/*  {this.props.selectedText} */}
                    <Icon className='valign-middle select__icon' path={ caretDown } size='28px' />
                    </span>
                </div>
            </div>
        )
    }
}

class Checkbox extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            checked: false
        }
    }
    render() {
        return (
            <label className="option option--checkbox">
                <input checked={this.props.value} type="checkbox" onChange={(e)=>{this.props.valueChanged(e.target.checked)}} className="option__input hiddenVisually" />
                <span className="flex option__label">
                    <span className="flex-shrink-none">
                        <span className="block option__labelMarker">
                            <Icon className='valign-middle block option__labelIcon' path={ check } size='22px' />
                        </span>
                    </span>
                    <span className="option__labelText">{this.props.children}</span>
                </span>
            </label>
        )
    }
}

class Checkbox2 extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            checked: false
        }
    }
    render() {
        return (
            <label className="option option--checkbox">
                <input checked={this.props.value} type="checkbox" onChange={(e)=>{this.props.valueChanged(e.target.checked)}} className="option__input hiddenVisually" />
                <span className="flex option__label">
                    <span className="flex-shrink-none">
                        <span className="block option__labelMarker">
                            <Icon className='valign-middle block option__labelIcon' path={ check } size='22px' />
                        </span>
                    </span>
                    <span onClick={()=>{this.props.onTextClicked()}} className="option__labelText">{this.props.children}</span>
                </span>
            </label>
        )
    }
}

class Radio extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            checked: false,
            name: this.props.name,
            onChange: this.props.onChange
        };
    }
    render() {
        return (
            <label className="option option--radio"><input onChange={this.state.onChange} name={this.state.name} type="radio" className="option__input hiddenVisually" /><span
                className="flex option__label"><span className="flex-shrink-none"><span
                className="block option__radioIcon"></span></span><span className="option__labelText">{this.props.children}</span></span></label>
        )
    }
}

class RadioGroup extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            checked: false,
            name: this.props.name,
            onChange: this.props.onChange
        };
    }
    render() {
        const children = React.Children.map(this.props.children, child => {
            return React.cloneElement(child, {
                name: this.props.name
            });
        });
        return(
            <div>
                { children }
            </div>
        )
    }
}

class Toggle extends React.Component {
    render() {
        return(<span className="sb-toggle"><input checked={this.props.checked} onChange={(e)=>{this.props.onChange(e)}} className="sb-toggle__input" type="checkbox" role="button" /><div
            className="sb-toggle__slider absoluteSpread" bis_skin_checked="1"></div></span>)
    }
}

class Field2 extends React.Component {
    static contextType = ThemeContext
    constructor(props){
        super(props);
        this.state = {
            focused: false,
            filled: false
        };
    }
    onFocus = () => {
        this.setState({focused: true})
    }
    onBlur = () => {
        this.setState({focused: false})
        if(this.props.onFocusLoose){
            this.props.onFocusLoose()
        }
    }
    onChange = (event) => {
        if(event.target.value) {
            this.setState({filled: true})
        } else {
            this.setState({filled: false})
        }
        this.props.valueChanged(event.target.value,event)
    }
    onkeydown = (event)=>{
        if(this.props.onkeydown){
            this.props.onkeydown(event)
        }
    }
    render() {
        if(this.props.error) {
            return (
                    <div className={'field ' + (this.props.className ? this.props.className : '') + (this.state.focused || this.props.value ? ' field--isFocused' : '') + (this.props.error ? ' field--isInvalid' : '')}>
                        <div className="field__inputWrapper flex">
                            <label className={'floatLabel ' + (this.context.primary === 'dark' ? 'color-textWhiteSoft ' : 'color-textBlackSoft ') + (this.state.focused || this.state.filled || this.props.value ? 'floatLabel--isActive' : '')} htmlFor={this.props.id}>{this.props.children}</label>
                            <input onKeyDown={this.onkeydown} value={this.props.value} type={this.props.type|| "text"} onFocus={this.onFocus} onBlur={this.onBlur} onChange={this.onChange} aria-invalid="false" className="fieldInput" id={this.props.id} autoComplete={(this.props.autocomplete ? 'on' : 'off')}/>
                            <Icon className='valign-middle field__warningIcon self-end flex-shrink-none mb1' path={ warningSolid } size='20px' />
                        </div>
                        <div>
                            <div>
                            <span className="block pt2 " id="fieldOne-validationHint">
                                <span className="fieldStatus fieldStatus--error">
                                <Icon className='valign-middle fieldStatus__icon' path={ close } size='20px' />
                                <span className="fieldStatus__text">{this.props.errorMessage}</span>
                                </span>
                            </span>
                            </div>
                        </div>
                    </div>
            )
        } else {
            return (
                    <div className={'field ' + (this.context.primary === 'dark' ? 'field--dark ' : '') + (this.props.className ? this.props.className : '') + (this.state.focused || this.props.value ? ' field--isFocused' : '') + (this.props.error ? ' field--isInvalid' : '')}>
                        <div className={'field__inputWrapper flex'}>
                            <label className={'floatLabel ' + (this.context.primary === 'dark' ? 'color-textWhiteSoft ' : 'color-textBlackSoft ') + (this.state.focused || this.state.filled || this.props.value ? 'floatLabel--isActive' : '')} htmlFor={this.props.id}>{this.props.children}</label>
                            <input onKeyDown={this.onkeydown} tabIndex="0" disabled={this.props.disable} value={this.props.value} type={this.props.type|| "text"} onFocus={this.onFocus} onBlur={this.onBlur} onChange={this.onChange} aria-invalid="false" className="fieldInput" id={this.props.id} autoComplete={(this.props.autocomplete ? 'on' : 'off')}/>
                        </div>
                    </div>

            )
        }

    }
}

export {Field, FieldStatus, FormContainer, EditField, Select, Checkbox, Radio, RadioGroup, Toggle, Select2 ,FieldMask,Checkbox2,Field2}