import axios from 'axios'
import getConfig from 'next/config'

const {serverRuntimeConfig, publicRuntimeConfig} = getConfig()

export function sbForgotPassword({
	email,
	phoneNumber
}) {

	return new Promise(async function (resolve, reject) {
				
		let url = publicRuntimeConfig.SB_AUTH_API+"/webForgotPassword"
		axios({
			method: 'post',
			url,
			data: {
				email,
				phoneNumber
            },
			headers: {
				"content-type": "application/json",
				"OperationChannel" :"web"
			}
		  }).then(function (response) {
			resolve(response)
		}).catch(function (error) {
			console.log("sbForgotPassword.js error.response is",error.response)
			resolve(error.response)
		})
	})
}