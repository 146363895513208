import axios from 'axios'
import getConfig from 'next/config'
import firebase from "firebase/app"
import {appValueChanged} from '../redux/app'

const {
    serverRuntimeConfig,
    publicRuntimeConfig
} = getConfig()

export function sbAddSbCard({
    cardNo,
    cvv
},dispatch) {

    return new Promise(async function (resolve, reject) {

        let url = publicRuntimeConfig.SB_AUTH_API+"/addSbuxCard"
        if (firebase && firebase.apps && firebase.apps.length) {
			if (firebase.auth().currentUser) {
				firebase.auth().currentUser.getIdToken().then(function (idToken) {
        axios({
            method: 'post',
            url,
            data: {
                cardNo,
                cvv
            },
            headers: {
                "content-type": "application/json",
                "Authorization": "Bearer " + idToken,
                "OperationChannel" :"web"
            }
        }).then(function (response) {
            resolve(response)
        }).catch(function (error) {
            resolve(error.response)
        })
    })}}
    })
}