import moment from 'moment'
import getConfig from 'next/config'
const { publicRuntimeConfig } = getConfig()

export function getWeekDay() {
  let d = new Date()
  let n = d.getDay()
  return n
}

export function getTurkishDayNamebyIndex(index) {
  let names = ["Pazar", "Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi"]
  let _index = index
  if (_index >= 7) {
    _index = _index - 7
  }
  return names[_index]
}

export function getDayNamebyIndex(index, locale) {
  let names = ["Pazar", "Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi"]
  if (locale == "en_US") {
    names = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
  }
  let _index = index
  if (_index >= 7) {
    _index = _index - 7
  }
  return names[_index]
}

export function getJsonKeyWorkingHours(index) {
  let _index = index
  if (_index >= 7) {
    _index = _index - 7
  }
  let keys = ["sundayWorkingHours", "mondayWorkingHours", "tuesdayWorkingHours", "wednesdayWorkingHours", "thursdayWorkingHours", "fridayWorkingHours", "saturdayWorkingHours"]
  return keys[_index]
}

export function isNumeric(value) {
  return /^-{0,1}\d+$/.test(value);
}

export function getMpAmount(amount) {
  let formattedAmount = amount = amount.replace(".", ",")
  let splitted = formattedAmount.split(",")
  let lastTwoDigits = "00"
  if (splitted[1] && splitted[1].length == 1) {
    lastTwoDigits = splitted[1] + "0"
  }
  if (splitted[1] && splitted[1].length == 2) {
    lastTwoDigits = splitted[1]
  }
  if (splitted[1] && splitted[1].length > 2) {
    lastTwoDigits = splitted[1][0] + splitted[1][1]
  }
  if (amount.indexOf(".") < 0 && amount.indexOf(",") < 0) {
    amount = amount + "00"
  }
  amount = splitted[0] + lastTwoDigits
  //console.log("getMpAmount is",amount)
  return amount
}

export function getPhoneNumber(value) {
  console.log("getPhoneNumber ", value)
  let maskedPhone = value
  let realNumber = maskedPhone.replace("(0)", "90").replace(/\s/g, "")
  //console.log("realNumber is",realNumber)
  return realNumber
}

export function isPhoneNumberValid(value) {
  //console.log("isPhoneNumberValid ", value)
  let realNumber = getPhoneNumber(value)
  //console.log("realNumber validation is", realNumber)
  if (realNumber.indexOf("-") < 0 || realNumber.length == 12) {
    return true
  }
  return false
}

export function isNumericOrEmpty(value) {
  if (value == "") {
    return true
  }
  return /^-{0,1}\d+$/.test(value);
}

export function isOtp(value) {
  if (isNumericOrEmpty(value) && value.length <= 6) {
    return true
  }
  return false
}

export function isLetterOrEmpty(inputtxt) {
  //var letters = /^[A-Za-z]+$/
  var letters = /^[a-zA-ZşŞıİçÇöÖüÜĞğ]+$/
  if (inputtxt.match(letters)) {
    return true
  } else if (inputtxt == "") {
    return true
  } else {
    return false
  }
}

export function isCardMonthValid(value) {
  if (value == "" || (isNumeric(value) && value.length <= 2 && parseInt(value) <= 12)) {
    return true
  }
  return false
}

export function isCardYearValid(value) {
  if (value == "" || (isNumeric(value) && value.length <= 2)) {
    return true
  }
  return false
}

export function getFilteredSuborders(subOrders) {
  return subOrders.filter(so => {
    return !so.productId.startsWith("packageFee") && !so.productId.startsWith("serviceFee")
  })
}

export function shortenString(str, moreThan) {
  if (str.length > moreThan) {
    return str.substring(0, moreThan) + "..."
  }
  return str
}

export function getDayNameFromDate(date) {
  //date => 27/05/2020
  let dayNames = ["Pazar", "Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi"]
  var dateFormatted = date.replace('/', '-')
  dateFormatted = dateFormatted.replace('/', '-')
  var date = moment(dateFormatted, "DDMMYYYY")
  var dow = date.day()
  return dayNames[dow]
}

export function get2YearLaterYYYYMMDD() {
  let twoyearlater = moment().add(2, 'year').format('YYYYMMDD')
  return twoyearlater
}

export function isValidEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase())
}


export function isProd() {
  let isProd = publicRuntimeConfig.RBS_PROJECT_ID == "cc1eec01745b4c0ba29d63b1fe7e6ccf"
  return isProd
}

export function hostName() {
  return isProd() ? "https://www.starbucks.com.tr" : "https://web-beta.sbuxtr.com"
}

export function turkishToEnglish(str) {
  return str
    .replace(/Ğ/g, "G")
    .replace(/ğ/g, "g")
    .replace(/Ü/g, "U")
    .replace(/ü/g, "u")
    .replace(/Ş/g, "S")
    .replace(/ş/g, "s")
    .replace(/İ/g, "I")
    .replace(/ı/g, "i")
    .replace(/Ö/g, "O")
    .replace(/ö/g, "o")
    .replace(/Ç/g, "C")
    .replace(/ç/g, "c")
}

export function sliceWithDotsAfter(str, len) {
  if (str.length >= len) {
    return str.slice(0, len) + "..."
  }
  return str
}

export function transformText(text) {
  const turkishToEnglish = {
    'ı': 'i',
    'İ': 'i',
    'i': 'i',
    'Ğ': 'g',
    'ğ': 'g',
    'Ü': 'u',
    'ü': 'u',
    'Ş': 's',
    'ş': 's',
    'Ö': 'o',
    'ö': 'o',
    'Ç': 'c',
    'ç': 'c',
    ' ': '-'
  };

  return text.replace(/[ıİiĞğÜüŞşÖöÇç ]/g, match => turkishToEnglish[match.toLowerCase()] || match).toLowerCase();
}