import axios from 'axios'
import getConfig from 'next/config'
import firebase from "firebase/app"
const {
    publicRuntimeConfig
} = getConfig()

export function sbManageAutoReload({cardName,endDate,loadAmount,action,loadThreshold,maskedCardNo},dispatch) 

{
    return new Promise(async function (resolve, reject) {

        let url = publicRuntimeConfig.SB_MP_API+"/manageAutoReload"

        if (firebase && firebase.apps && firebase.apps.length) {
			if (firebase.auth().currentUser) {
				firebase.auth().currentUser.getIdToken().then(function (idToken) {
        axios({
            method: 'post',
            url,
            data: {
                cardName,
                endDate, //backend otomatik 2 yıl ekliyor.
                loadAmount,
                action,
                loadThreshold,
                maskedCardNo
            },
            headers: {
                "content-type": "application/json",
                "Authorization": "Bearer " + idToken,
                "OperationChannel" :"web"
            }
        }).then(function (response) {
            resolve(response)
        }).catch(function (error) {
            resolve(error.response)
        })
      })
    }}
    })
}