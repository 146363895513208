import loadFirebase from "../lib/db"
import Router from 'next/router'

export async function signOut(){
    const firebase = await loadFirebase()
    firebase.auth().signOut().then(function() {
        console.log("authHelper.js firebase signedOut successfully")
        Router.push("/login")
    }).catch((error)=> {
        console.log("authHelper.js signOut error ",error)
    })
}

export async function signOutAsync(){
    const firebase = await loadFirebase()
    return new Promise((resolve, reject) => {
        firebase.auth().signOut().then(function() {
            console.log("authHelper.js firebase signOutAsync successfully")
            Router.push("/login")
            resolve(true)
        }).catch((error)=> {
            console.log("authHelper.js signOut error ",error)
            reject(false)
        })
    })
}
            