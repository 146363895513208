import axios from 'axios'
import getConfig from 'next/config'
import firebase from "firebase/app"

const {
    serverRuntimeConfig,
    publicRuntimeConfig
} = getConfig()

export function sbCreateSbCard({
    firstName,
    lastName,
    tckn,
    gender,
    birthDate,
    refId,
}) {

    return new Promise(async function (resolve, reject) {

        let url = publicRuntimeConfig.SB_AUTH_API+"/createSbuxCard"

        if (firebase && firebase.apps && firebase.apps.length) {
			if (firebase.auth().currentUser) {
				firebase.auth().currentUser.getIdToken().then(function (idToken) {
        axios({
            method: 'post',
            url,
            data: {
                firstName,
                lastName,
                tckn,
                gender,
                birthDate,
                refId,
            },
            headers: {
                "content-type": "application/json",
                "Authorization": "Bearer " + idToken,
                "OperationChannel" :"web"
            }
        }).then(function (response) {
            resolve(response)
        }).catch(function (error) {
            resolve(error.response)
            //reject(error)
        })
      })
    }}
    })
}