
import firebase from "firebase/app"
import "firebase/auth"
import 'firebase/firebase-firestore'
import getConfig from 'next/config'

const {serverRuntimeConfig, publicRuntimeConfig} = getConfig()
let firebaseConfig = {
    apiKey: publicRuntimeConfig.FIREBASE_API_KEY,
    authDomain: publicRuntimeConfig.FIREBASE_AUTH_DOMAIN,
    databaseURL: publicRuntimeConfig.FIREBASE_DATABASE_URL,
    projectId: publicRuntimeConfig.FIREBASE_PROJECT_ID,
    storageBucket: publicRuntimeConfig.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: publicRuntimeConfig.FIREBASE_MESSAGING_SENDER_ID,
    appId: publicRuntimeConfig.FIREBASE_APP_ID,
  }

export default async function loadFirebase(){
    try{
        const app = firebase.initializeApp(firebaseConfig)
        firebase.firestore(app).settings({ experimentalForceLongPolling: true })
    }catch(error){
        //console.log("db.js firebase err")
    }
    return firebase
}