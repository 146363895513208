import axios from 'axios'
import getConfig from 'next/config'
import firebase from "firebase/app"

const {
	serverRuntimeConfig,
	publicRuntimeConfig
} = getConfig()

export function sbInsertOrder({
	dispatch,
	amount,
	cardId,
	cardName,
	maskedCardNo
}) {
	return new Promise(async function (resolve, reject) {

		let url = publicRuntimeConfig.SB_ORDER_API+"/insertOrder"
		if (firebase && firebase.apps && firebase.apps.length) {
			if (firebase.auth().currentUser) {
				firebase.auth().currentUser.getIdToken().then(function (idToken) {
					axios({
						method: 'post',
						url,
						data: {
							//"validationMethod": "none",
							"amount": amount,
							"cardId": cardId,
							"cardName":cardName,
							"maskedCardNo": maskedCardNo
						},
						headers: {
							"content-type": "text/plain",
							"Authorization": "Bearer " + idToken,
							"OperationChannel" :"web"
						}
					}).then(function (response) {
						resolve(response)
					}).catch(function (error) {
						resolve(error.response)
					})
				}).catch(function (error) {
					console.log("currentUser.getIdToken error")
				})
			}
		}
	})
}