import axios from 'axios'
import getConfig from 'next/config'
import firebase from "firebase/app"
import {appValueChanged} from '../redux/app'

const {
    serverRuntimeConfig,
    publicRuntimeConfig
} = getConfig()

export function sbUpdateProfile({
    firstName,
    lastName,
    gender,
    birthDate,
    allowEmail,
    allowSms,
    masterpassPhoneNumber,
    email,
    phoneNumber,
    password,
    oldPassword,
    preferredName,
    preferredLocale,
    pinStatus,
    terms,
    personalconsentform,
    personaldisc

},dispatch) {

    return new Promise(async function (resolve, reject) {

        let url = publicRuntimeConfig.SB_AUTH_API+"/updateProfile"
        if (firebase && firebase.apps && firebase.apps.length) {
			if (firebase.auth().currentUser) {
				firebase.auth().currentUser.getIdToken().then(function (idToken) {
        axios({
            method: 'post',
            url,
            data: {
                firstName,
                lastName,
                gender,
                birthDate,
                allowEmail,
                allowSms,
                masterpassPhoneNumber,
                email,
                phoneNumber,
                password,
                oldPassword,
                preferredName,
                preferredLocale,
                pinStatus,
                terms,
                personalconsentform,
                personaldisc
            },
            headers: {
                "content-type": "application/json",
                "Authorization": "Bearer " + idToken,
                "OperationChannel" :"web"
            }
        }).then(function (response) {
            resolve(response)
        }).catch(function (error) {
            resolve(error.response)
        })
    })}}
    })
}