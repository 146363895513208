import React from 'react'
//import './styles.scss'
const ThemeContext = React.createContext({primary: 'default', rewards: 'none'});
export default ThemeContext

/*
class ThemeContext extends React.Component {

    render() {
        React.createContext({primary: 'dark', rewards: 'gold'})
        const children = React.Children.map(this.props.children, child => {
            return React.cloneElement(child, {
                primary: this.props.value.primary,
                rewards: this.props.value.rewards
            });
        });
        return(
            <div>
                { children }
            </div>
        )
    }
}

ThemeContext.Provider = ThemeContext

export default ThemeContext*/
