import axios from 'axios'
import getConfig from 'next/config'

const {
    serverRuntimeConfig,
    publicRuntimeConfig
} = getConfig()

export function sbSignUp({
    firstName,
    lastName,
    email,
    phoneNumber,
    password,
    allowSms,
    allowEmail,
    tckn,
    gender,
    preferredName,
    inviteCode
}) {

    return new Promise(async function (resolve, reject) {

        let url = publicRuntimeConfig.SB_AUTH_API+"/signUp"
        axios({
            method: 'post',
            url,
            data: {
                firstName,
                lastName,
                email,
                phoneNumber,
                password,
                allowSms,
                allowEmail,
                tckn,
                gender,
                preferredName,
                inviteCode
            },
            headers: {
                "content-type": "application/json",
                "OperationChannel" :"web"
            }
        }).then(function (response) {
            resolve(response)
        }).catch(function (error) {
            resolve(error.response)
            //reject(error)
        })
    })
}