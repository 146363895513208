import axios from 'axios'
import getConfig from 'next/config'

const {
    publicRuntimeConfig
} = getConfig()

export function getContent(params) {

    let screen = params.screen
    let segment = params.segment
    let path = params.path ? params.path : "getContent"
    let contentId  = params.contentId
    let url = ""
    if(path=="getSingleContent"){
        url = publicRuntimeConfig.SB_CONTENT_API + `/getSingleContent?contentId=${contentId}`
    }else if(path="getContent"){
        url = publicRuntimeConfig.SB_CONTENT_API + `/getContent?segment=${segment}&screen=${screen}`
    }
    return new Promise(async function (resolve, reject) {
        axios({
                method: 'get',
                url,
                data: {},
                headers: {
                    //"OperationChannel" :"web"
                }
            })
            .then(function (response) {
                resolve(response)
            })
            .catch(function (error) {
                reject(error)
            })
    })
}