import React from 'react'
import Rio from "@retter/sdk"
import { Provider } from 'react-redux'
import App from 'next/app'
import withRedux from 'next-redux-wrapper'
import Router from 'next/router'
import SBlayout from '../components/SBlayout'
import { initializeStore } from '../store'
import { valueChanged as authValueChanged, logout } from '../redux/auth'
import { showModalWithText, appValueChanged } from '../redux/app'
import { valueChanged as userValueChanged } from '../redux/user'
import * as userActions from '../redux/user'
import * as catalogActions from '../redux/catalog'
import loadFirebase from "../lib/db"
import 'isomorphic-fetch'
import Cookies from "js-cookie"
import { getContent } from '../helpers/sbGetContent'
import { getLocale, getSegment, checkFormVersions } from "../helpers/stateFunctions"
import { TEXTS } from "../helpers/statics"
import axios from 'axios'
import { signOutAsync } from "../helpers/authHelper"
import sha256 from "sha256"
import { datalayer_login, datalayer_signUp } from "../helpers/datalayer"
import { getRioInstance, rioGetUserInfo } from '../helpers/rioHelper'
import { isProd } from '../helpers/utility'

import "./globals.css";
import "react-datepicker/dist/react-datepicker.css";
// import 'tailwindcss/tailwind.css'
class MyApp extends App {

  static async getInitialProps({ Component, ctx, res }) {
    let host = ctx?.req?.headers?.host
    let url = ctx?.req?.url
    if (host && host.indexOf("starbuckscardtr") >= 0) {
      ctx.res.writeHead(302, {
        Location: 'https://www.starbucks.com.tr' + url || "",
        'Content-Type': 'text/html; charset=utf-8',
      })
      ctx.res.end()
      return {}
    }

    return {
      pageProps: Component.getInitialProps ? await Component.getInitialProps(ctx) : {},
      host,
      url
    }
  }

  changeUserValue(key, value) {
    this.props.store.dispatch(userActions.valueChanged(key, value))
  }

  onRevokeTimeChanged() {
    this.props.store.dispatch(showModalWithText(TEXTS.revokeTimeMessage[getLocale(this.props.store.getState())]))
    Router.push("/login")
  }

  async checkRevokeTime(newRevokeTime) {
    let currentStateRevokeTime = this.props.store.getState().auth.revokeTime
    if (!currentStateRevokeTime) {
      this.props.store.dispatch(authValueChanged("revokeTime", newRevokeTime))
      return
    }
    if (currentStateRevokeTime && currentStateRevokeTime != newRevokeTime) {
      let _this = this
      const firebase = await loadFirebase()
      firebase.auth().signOut().then(function () {
        if (_this.onRevokeTimeChanged) {
          _this.onRevokeTimeChanged()
        }
      }).catch((error) => {
      })
    }
  }

  async getSegmentContent(screen = "banner") {
    let segment = getSegment(this.props.store.getState())
    let getContentResult = null
    try {
      getContentResult = await getContent({ screen, segment })
      if (getContentResult && getContentResult.data && getContentResult.status >= 200 && getContentResult.status < 300) {
        this.props.store.dispatch(catalogActions.contentValueChanged(screen, getContentResult.data))
      }
    }
    catch (e) {
    }
  }

  checkRoute(user) {
    let returnUrl = Router.router.query.returnUrl
    if (returnUrl?.startsWith("/")) {
      returnUrl = returnUrl.substring(1)
    }
    let route = Router.router.route
    let ref = Router.router.query.ref
    const action = Router.query.action

    //user rota kuralları - external'dan logine yönlenen ticimax user olsa bile loginde kalmalı.
    if (user) {
      if (action && action?.toLowerCase() == "mpnochange") {
        Router.push("/account?section=paymentMethods&action=mpnochange", "/account/paymentMethods").then(() => { window.scrollTo(0, 0) })
      } else if (route.indexOf("login") >= 0 && ref != "ticimax") {
        if (returnUrl) {
          Router.push("/" + returnUrl).then(() => { window.scrollTo(0, 0) })
          return
        } else {
          Router.push("/")
        }
      }
    }
  }

  async componentDidMount() {
    let _this = this

    if (window.location.href.indexOf("csurvey") < 0) {
      axios.interceptors.response.use(response => {
        this.props.store.dispatch(appValueChanged("apiResponse", response))
        return response
      }, error => {
        //console.log('axios interceptor Error is:', error.response)
        if (error.response && error.response.status == 401) {
          //console.log("401 geldi logout")
          signOutAsync().then(() => {
            this.props.store.dispatch(logout())
            this.props.store.dispatch(showModalWithText(TEXTS.sessionExpiredMessage[getLocale(this.props.store.getState())]))
          })
        } else {
          this.props.store.dispatch(appValueChanged("apiResponse", error.response))
        }
        return Promise.resolve(error.response)
      })
    }

    let userPartition = sha256(Math.floor(Math.random() * 100).toString()) // 0-99
    this.props.store.dispatch(userValueChanged("userPartition", userPartition))

    let culture = Cookies.get("preferredLocale") == "en" ? "en-us" : "tr-tr"
    const rio = Rio.getInstance({
      url: "api.sbuxtr.com",
      projectId: isProd() ? "asnt778q" : "g7r5gvep",
      culture,
    })
    rio.authStatus?.subscribe(async (status) => {
      if (status.authStatus === "SIGNED_IN") {
        const response = await rioGetUserInfo(status?.uid);
        if (response?.data?.userInfo) {
          _this.changeUserValue("user", { ...response?.data?.userInfo, stars: response?.data?.stars })
        }
        this.props.store.dispatch(authValueChanged("challenge", { stage: 'NEW' }))
        this.checkRoute(response?.data.userInfo)
        if (Router.asPath.indexOf("resetPassword") >= 0) {
          let rio = getRioInstance()
          const rioNewProject = Rio.getInstance({
            url: "api.sbuxtr.com",
            projectId : isProd()?"asnt778q":"g7r5gvep",
            culture,
          })
          rioNewProject.signOut().finally(() => {})
          rio.signOut().finally(() => {})
          this.props.store.dispatch(authValueChanged("revokeTime", null))
          this.props.store.dispatch(authValueChanged("isAnonym", true))
          this.props.store.dispatch(userActions.valueChanged("user", null))
        }
      } else if (status.authStatus === "SIGNED_OUT") {
        this.props.store.dispatch(authValueChanged("revokeTime", null))
        this.props.store.dispatch(authValueChanged("isAnonym", true))
        this.props.store.dispatch(userActions.valueChanged("user", null))
      }
    });

    const firebase = await loadFirebase()
    let db = firebase.firestore()
    db.collection("content/settings/partitions").doc(userPartition)
      .onSnapshot(function (doc) {
        _this.props.store.dispatch(catalogActions.catalogValueChanged("settings", doc.data()?.items))
      })
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.props.store.dispatch(authValueChanged("isAnonym", false))
        let uid = user.uid
        db.collection("users").doc(uid).collection("activity")
          .orderBy("timestamp", "desc")
          .limit(25)
          .onSnapshot((doc) => {
            let activities = []
            doc.docs.forEach((act) => {
              activities.push(act.data())
            })
            _this.changeUserValue("activities", activities)
            _this.changeUserValue("lastActivity", activities[0] ? activities[0] : null)
            if (activities[0] && activities[0].status == "pending") {
              _this.changeUserValue("lastPendingOrder", activities[0])
            }
          })

        _this.unsubscribeUsers = db.collection("users").doc(uid)
          .onSnapshot(function (doc) {
            let isSignUp = Cookies.get("signup") == "true"
            let currentUser = doc.data()
            if (isSignUp) {
              datalayer_signUp({ uid: currentUser.sbuxUserId })
              Cookies.remove("signup")
            } else if (!_this.props.store.getState().app.datalayer_login) { // 2 kere datalayer_login firlatmamasi için. 
              if (Cookies.get("datalayer_login") != "true") {
                let balance = currentUser?.cards?.[0]?.balance || ""
                datalayer_login({ uid: currentUser?.sbuxUserId, segmentType: currentUser?.segment?.type, balance })
                Cookies.set("datalayer_login", "true")
                _this.props.store.dispatch(appValueChanged("datalayer_login", true))
              }
            }
            _this.changeUserValue("user", currentUser)
            _this.getSegmentContent()
            db.collection("content/forms/partitions").doc(userPartition).onSnapshot(function (document) {
              let formsLates = document.data().items
              _this.props.store.dispatch(catalogActions.catalogValueChanged("checkFormVersions", checkFormVersions(formsLates, currentUser)))
            })
          })

        _this.unsubscribeUsers = db.collection("metadata").doc(uid)
          .onSnapshot(function (doc) {
            let metadata = doc.data()
            let revokeTime = metadata ? metadata.revokeTime : ""
            _this.checkRevokeTime(revokeTime)
          })
        this.checkRoute(user)
      }
      //User yoksa
      else {
        this.props.store.dispatch(authValueChanged("revokeTime", null))
        this.props.store.dispatch(authValueChanged("isAnonym", true))
        this.props.store.dispatch(userActions.valueChanged("user", null))
        this.getSegmentContent()
      }
    })
  }

  render() {
    const { Component, pageProps, store, host, url } = this.props
    return (
      <Provider store={store}>
        <SBlayout store={store} host={host} url={url}>
          <Component {...pageProps} />
        </SBlayout>
      </Provider>
    )
  }
}
export default withRedux(initializeStore)(MyApp)

