import React from 'react'
/* import './styles.scss'*/
import ThemeContext from "../theme-context" 

class Button extends React.Component {
   //static contextType = ThemeContext;
    render() {
        let tagName = 'button';
        if(this.props.tagName) {
            tagName = this.props.tagName;
        }
        let href = '';
        if(this.props.href) {
            href = this.props.href;
        }
        let type = 'button';
        if(this.props.type) {
            type = this.props.type;
        }
        let visualStyle = 'default';
        if(this.props.visualStyle) {
            visualStyle = this.props.visualStyle
            if(visualStyle === 'textOnly') {
                visualStyle = 'text'
            }
        }
        let extraClasses = '';
        if(this.props.className) {
            extraClasses = this.props.className
        }
        let buttonClasses = 'sb-button sb-button--' + visualStyle + ' ' + extraClasses;
        if(this.props.disabled) {
            buttonClasses += ' sb-button--disabled';
        }
        if(this.props.size === 'lg') {
            buttonClasses += ' sb-button--large';
        }
        if(this.context.primary === 'dark') {
            buttonClasses += ' sb-button--dark'
        }
        if(this.context.rewards === 'gold') {
            buttonClasses += ' sb-button--rewardsGold'
        }
        if(this.props.visualStyle === 'textLink') {
            buttonClasses = 'sb-textLink ' + extraClasses;
        }
        if(tagName === 'button' && !href) {
            return (
                <button onClick={this.props.onClick} className={buttonClasses} type={type} disabled={this.props.disabled} style={this.props.style}>{this.props.children}</button>
            )
        } else {
            return (
                <a className={buttonClasses} href={href} style={this.props.style}>{this.props.children}</a>
            )
        }
    }
}

class Frap extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            clicked: false,
        };
        this.rippleEffect = this.rippleEffect.bind(this);
    }
    rippleEffect(event){
        const posX = (event.pageX - event.target.offsetLeft) - 50;
        const posY = (event.pageY / event.target.offsetHeight) - 25;
        this.setState({
            clicked: !this.state.clicked,
            posX,
            posY,
        });
        const self = this;
        setTimeout(() => {
            this.props.onClick();
            this.setState({
                clicked: !self.state.clicked,
            })
        }, 600);
    }
    render() {
        const {children= null, classes = "", onClickHandler = null} = this.props;
        let frapClasses = 'sb-frap'
        let rippleClasses = 'sb-ripple absolute'
        if(this.props.mini) {
            frapClasses += ' sb-frap--mini';
            rippleClasses += ' bg-black50';
        }
        if(this.props.payInStore){
            frapClasses += ' payInStore';
        }
        if(this.props.disabled) {
            frapClasses += ' sb-frap--disabled';
        }
        if(this.props.loading) {
            frapClasses += ' sb-frap--loading';
        }
        if(this.props.loading) {
            return (
                <button onClick={()=>this.props.onClick()} className={frapClasses} style={this.props.style}>
                    <div className="sb-frap--loadingCircle"></div>
                    { this.state.clicked
                    && <span className={rippleClasses} style={{ top: `${this.state.posY}px`, left: `${this.state.posX}px` }} /> }
                </button>
            )
        }
        if(this.props.disabled) {
            return (
                <button className={frapClasses} style={this.props.style}>
                    {this.props.children}
                    { this.state.clicked
                    && <span className={rippleClasses} style={{ top: `${this.state.posY}px`, left: `${this.state.posX}px` }} /> }
                </button>
            )
        } else {
            return (
                <button className={frapClasses + (this.props.step ? ' sb-animator-fade-enter-active' : '')} onClick={this.rippleEffect} style={this.props.style}>
                    {this.props.children}
                    { this.state.clicked
                    && <span className={rippleClasses} style={{ top: `${this.state.posY}px`, left: `${this.state.posX}px` }} /> }
                </button>
            )
        }
    }
}

export {Button, Frap}