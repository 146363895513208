import axios from 'axios'
import firebase from "firebase/app"
import {
    appValueChanged
} from '../redux/app'

export function sbRequest(url, params, dispatch, verb) {

    return new Promise(async function (resolve, reject) {
        if (firebase && firebase.apps && firebase.apps.length) {
            if (firebase.auth().currentUser) {
                firebase.auth().currentUser.getIdToken().then(function (idToken) {
                    axios({
                        //method: 'post',
                        method: verb,
                        url,
                        data: {
                            ...params
                        },
                        headers: {
                            "content-type": "application/json",
                            "Authorization": "Bearer " + idToken,
                            "OperationChannel": "web"
                        }
                    }).then(function (response) {
                        dispatch(appValueChanged("apiResponse", response))
                        resolve(response)
                        dispatch(appValueChanged("showLoading", false))
                    }).catch(function (error) {
                        dispatch(appValueChanged("apiResponse", error))
                        resolve(error.response)
                        dispatch(appValueChanged("showLoading", false))
                    })
                })
            }
        }
    })
}