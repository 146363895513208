import {
    sbInsertOrder
} from '../helpers/sbInsertOrder'
import {
    getUserCardsList
} from '../helpers/stateFunctions'

const CART_VALUE_CHANGED = 'cart/CART_VALUE_CHANGED'
const APP_VALUE_CHANGED = 'app/APP_VALUE_CHANGED'

const INSERT_ORDER_SB = 'cart/INSERT_ORDER_SB'
const INSERT_ORDER_SB_SUCCESS = 'cart/INSERT_ORDER_SB_SUCCESS'

const initialState = {
    priceToPayStr: "",
    amount: "",
    orderId: "",
    purchaseToken: "",
    errorMsg: "",
    lastOrder: null,
}

export default (state = initialState, action) => {
    switch (action.type) {

        case CART_VALUE_CHANGED: {
            return {
                ...state,
                [action.key]: action.value
            }
        }
        default:
            return state
    }
}

const cartValueChanged = (key, value) => {
    return {
        type: CART_VALUE_CHANGED,
        key,
        value
    }
}

const insertOrderSB = (params) => {

    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch({
                type: INSERT_ORDER_SB,
            })
            let amount = getState().cart.amount
            let cardName = params && params.akbank ? "AKBANKSANALPOS" : getState().user.selectedPaymentMethod ? getState().user.selectedPaymentMethod.Name : null
            let maskedCardNo = params && params.akbank ? "AKBANKSANALPOS" : getState().user.selectedPaymentMethod ? getState().user.selectedPaymentMethod.Value1 : null
            let isAkbank = params && params.akbank ? true : false
            sbInsertOrder({
                    dispatch,
                    amount,
                    cardId: getUserCardsList(getState())[0].cardId, //todo ilk karta yüklüyorum hep secmeli olacak burasi
                    cardName,
                    maskedCardNo
                })
                .then(function (data) {
                    data.isAkbank = isAkbank
                    dispatch({
                        type: APP_VALUE_CHANGED,
                        key: "apiResponse", 
                        value: data,
                        apiName: "insertOrderSB"
                    })
                    resolve(data)
                })
                .catch(function (err) {
                    reject(err)
                })
        })
    }
}

export {
    cartValueChanged,
    insertOrderSB,
}