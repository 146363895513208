import { Button, Frap } from "./button";

const SBsimpleModal = (props) => (
  <div className="sb-white-dialog-box">
    <div className="in-modal modal-just-text">
      {props.onClose && (
        <img
          onClick={() => {
            props.onClose();
          }}
          style={{ float: "right", cursor: "pointer" }}
          className="close-icon"
          src="https://content.sbuxtr.com/images/svg/v2-close-modal-icon.svg"
        />
      )}
      <div className="text">
        <span className="title" style={{ fontSize: props.fontSize }}>
          {props.titleText}
        </span>
        {props.Msgs && props.Msgs.length > 0 && (
          <p className="content">{props.Msgs}</p>
        )}
        {props.Msgs2 && props.Msgs2.length > 0 && (
          <p className="content">{props.Msgs2}</p>
        )}
        {props.Msgs3 && props.Msgs3.length > 0 && (
          <p className="content">{props.Msgs3}</p>
        )}
      </div>

      <div className="actions flex justify-end">
        {props.btn1Text && (
          <Button
            className="mr3"
            visualStyle="textOnly"
            type="button"
            onClick={() => {
              props.onClickBtn1();
            }}
          >
            {props.btn1Text}
          </Button>
        )}
        {props.btn2Text && (
          <Button
            className="mr3"
            visualStyle="positive"
            type="button"
            onClick={() => {
              props.onClickBtn2();
            }}
          >
            {props.btn2Text}
          </Button>
        )}
        {props.btn3Text && (
          <Button
            className="mr3"
            visualStyle="positive"
            type="button"
            onClick={() => {
              props.onClickBtn3();
            }}
          >
            {props.btn3Text}
          </Button>
        )}
      </div>
    </div>
  </div>
);

export default SBsimpleModal;
