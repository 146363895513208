import {Button, Frap} from './button'
import {Checkbox2} from '../components/forms'
import {getLocale,getTermsOfUse,getPersonalDisc,getPersonalConsentForm}  from "../helpers/stateFunctions"
import {TEXTS} from "../helpers/statics"

const FormsModal = ({_state,checkFormVersions,changeStateKV,approveForms,cboxKvkk1,cboxKvkk2,approveDisabled}) => (
    
    <div className="sb-white-dialog-box">
        <div className="in-modal modal-just-text">
            <h2 className="text-xxs text-bold text-upper mb4 color-textBlackSoft">{TEXTS.termsOfUse[getLocale(_state)]}</h2>
                <div> 
                    {!checkFormVersions.terms&&
                    <Checkbox2 value={cboxKvkk1} valueChanged={v=>{changeStateKV("cboxKvkk1",v)}} onTextClicked={()=>{}}>
                                {getLocale(_state)=="tr"&&
                                <span><span onClick={()=>{
                                var win = window.open(getTermsOfUse(_state), '_blank')
                                win.focus()
                                }} style={{textDecoration: "underline",textDecorationColor:"#006341",color:"green"}}>Starbucks Card Programı Üyelik Sözleşmesi ve Program Kullanım Koşulları</span>'nı okudum ve kabul ediyorum</span>}  
                                
                                {getLocale(_state)=="en"&&
                                <span>I have read and hereby agree and confirm the <span onClick={()=>{
                                var win = window.open(getTermsOfUse(_state), '_blank')
                                win.focus()
                                }} style={{textDecoration: "underline",textDecorationColor:"#006341",color:"green"}}>Starbucks Card Program Membership Agreement and Program Terms of Use</span></span>}  
                    </Checkbox2>}

                    {(!checkFormVersions.personalconsentform || !checkFormVersions.personaldisc) &&
                    <Checkbox2 value={cboxKvkk2} valueChanged={v=>{changeStateKV("cboxKvkk2",v)}}  onTextClicked={()=>{}} > 
                                {getLocale(_state)=="tr"&&
                                <span><span onClick={()=>{
                                var win = window.open(getPersonalDisc(_state), '_blank')
                                win.focus()
                                }} style={{textDecoration: "underline",textDecorationColor:"#006341",color:"green"}}>Kişisel Veri Aydınlatma Metni</span>'ni okudum. 
                                <span onClick={()=>{
                                    var win = window.open(getPersonalConsentForm(_state), '_blank')
                                    win.focus()
                                    }} style={{textDecoration: "underline",textDecorationColor:"#006341",color:"green"}}>Kişisel Verilerin İşlenmesi Açık Rıza Formu</span>'nu okudum ve kabul ediyorum.</span>}  
                                
                                {getLocale(_state)=="en"&&
                                <span>I have read the
                                <span onClick={()=>{
                                var win = window.open(getPersonalDisc(_state), '_blank')
                                win.focus()
                                }} style={{textDecoration: "underline",textDecorationColor:"#006341",color:"green"}}> Personal Data Information Notice.</span> 
                                I have read and hereby agree and confirm the 
                                <span onClick={()=>{
                                    var win = window.open(getPersonalConsentForm(_state), '_blank')
                                    win.focus()
                                    }} style={{textDecoration: "underline",textDecorationColor:"#006341",color:"green"}}> Express Consent Form for the Processing of Personal Data</span></span>}  
                    </Checkbox2>}

                    <div className="actions flex justify-end">
                        <Button className="mr3" visualStyle="textOnly" type="button" onClick={()=>{changeStateKV("showFormModal",false)}}>{TEXTS.cancel[getLocale(_state)]}</Button>
                        <Button disabled={approveDisabled} className="mr3" visualStyle="positive" type="button" onClick={()=>{approveForms()}}>{TEXTS.verify[getLocale(_state)]}</Button>            
                    </div>         
                </div>
        </div>
    </div>
)
export default FormsModal