import Router from 'next/router'
import {
  showModalWithText
} from '../redux/app'
const authMiddleware = store => next => action => {

  //api sonrasi apiResponse dispatch edersem buradan 401 vs 'lere göre aksiyon alabilirim.
  if (action.key == "apiResponse") {
    let preferredLocale = "tr"
    let user = store.getState().user
    if (user && user.user) {
      preferredLocale = user.user.preferredLocale
    }
    let httpStatus = (action.value && action.value.response && action.value.response.status) ? action.value.response.status : ""
    next(action)
    //! 401 ise next(action) yapmıyorum login sayfasina gonderiyorum
    if (httpStatus == 401 && false) {
      //let modalText = preferredLocale == "en" ? "You must login to continue" : "Tekrar giriş yapmalısınız"
      //store.dispatch(showModalWithText(modalText))
      //Router.push("/login")
    } else {
      //! 401 dışındakiler normal akışıonda devam ediyor layout mesajları basıyor...
      next(action)
    }
  }
  //! apiResponse dışındakiler normal akışıonda devam ediyor...
  else {
    next(action)
  }
}

export default authMiddleware