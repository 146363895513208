const TermModal = (props) => (
  <div className="sb-term-modal">
    <div className="in-modal">
      {props.onClose && <img onClick={() => { props.onClose() }} style={{ float: "right", cursor: "pointer" }} className="close-icon" src="https://content.sbuxtr.com/images/svg/v2-close-modal-icon.svg" />}

      <object
        data={props.url}
        type="text/html"
      />
    </div>
  </div>
)

export default TermModal