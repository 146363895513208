import axios from 'axios'
import getConfig from 'next/config'
import Cookies from "js-cookie"

const {serverRuntimeConfig, publicRuntimeConfig} = getConfig()

export function sbSAnswerChallenge({
	challengeId,
	challengeAnswer,
	phoneNumber,
	newPassword,
	userId,
}) {
	let deviceId = Cookies.get("deviceId")
	if (!deviceId) {
		Cookies.set('deviceId', Math.floor(Math.random() * 10000))
		deviceId = Cookies.get("deviceId")
	}
	return new Promise(async function (resolve, reject) {
				
		let url = publicRuntimeConfig.SB_AUTH_API+"/answerChallenge"
		axios({
			method: 'post',
			url,
			data: {
				challengeId,
				challengeAnswer,
				phoneNumber,
				newPassword,
				userId,
				deviceId
            },
			headers: {
				"content-type": "application/json",
				"OperationChannel" :"web"
			}
		  }).then(function (response) {
			resolve(response)
		}).catch(function (error) {
			resolve(error.response)
		})
	})
}