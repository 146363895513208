import axios from 'axios'
import getConfig from 'next/config'

const {
    serverRuntimeConfig,
    publicRuntimeConfig
} = getConfig()

export function sbResendConfirmationCode({
    phoneNumber,
    email,
}) {

    return new Promise(async function (resolve, reject) {

        let url = publicRuntimeConfig.SB_AUTH_API+"/resendConfirmationCode"
        axios({
            method: 'post',
            url,
            data: {
                email,
                phoneNumber,
            },
            headers: {
                "content-type": "application/json",
                "OperationChannel" :"web"
            }
        }).then(function (response) {
            resolve(response)
        }).catch(function (error) {
            console.log("resendConfirmationCode.js error.response is", error.response)
            resolve(error.response)
        })
    })
}