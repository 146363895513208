import axios from 'axios'
import getConfig from 'next/config'
//import {appValueChanged} from '../redux/app'
import {valueChanged} from '../redux/auth'
import firebase from "firebase/app"

const {serverRuntimeConfig, publicRuntimeConfig} = getConfig()

export function mp({
	dispatch,
	amount,
	cardObject,
}) 
{
	return new Promise(async function (resolve, reject) {
				
		let url = publicRuntimeConfig.SB_MP_API+"/mpToken"
		
		if(firebase && firebase.apps && firebase.apps.length){
			if(firebase.auth().currentUser){
				firebase.auth().currentUser.getIdToken().then(function(idToken) {
					axios({
						method: 'post',
						url,
						data: {
						   "validationMethod": "none",
						   "amount":amount,
							"cardObject":cardObject
						},
						headers: {
							"content-type": "text/plain",
							"Authorization": "Bearer "+idToken,
							"OperationChannel" :"web"
						}
					}).then(function (response) {
						//dispatch(valueChanged("masterpassToken",idToken))
						resolve(response)
					}).catch(function (error) {
						alert(error)
						console.log("error is ",error)
						reject(error)
					})
				  }).catch(function(error) {
					console.log("currentUser.getIdToken error")
				  })
			}
		}
	})
}


