export function datalayer_signUp(params) {
  window.dataLayer = window.dataLayer || [];
  let eventPayload = {
    event: "sign_up",
    userId: params.uid,
    member_status: "Green", // default Green başlar signup'da.
  };
  window.dataLayer.push(eventPayload);
}

export function datalayer_login(params) {
  window.dataLayer = window.dataLayer || [];
  let eventPayload = {
    event: "login",
    userId: params.uid,
    member_status: params.segmentType,
    balance: params.balance,
  };
  window.dataLayer.push(eventPayload);
}

export function user_data(params) {
  window.dataLayer = window.dataLayer || [];
  let eventPayload = {
    event: "user_data",
    userId: params.uid,
    member_status: params.segmentType,
    balance: params.balance,
  };
  window.dataLayer.push(eventPayload);
}

export function datalayer_view_item(params) {
  console.log(params);
  window.dataLayer = window.dataLayer || [];
  let eventPayload = {
    event: "view_item",
    ecommerce: {
      items: [
        {
          item_id: params.item_id,
          item_name: params.item_name, //"Stan and Friends Tee",
          currency: "TRY",
          index: 0,
          item_brand: "Starbucks",
          item_category: params.cat1,
          item_category2: params.cat2,
          item_category3: params.cat3,
          quantity: 1,
        },
      ],
    },
  };
  if (!params.cat3) delete eventPayload.ecommerce.items[0].item_category3;
  window.dataLayer.push(eventPayload);
}

export function datalayer_insert_money(params) {
  window.dataLayer = window.dataLayer || [];
  let eventPayload = {
    event: "insert_money",
    value: params.value,
    payment_type: "Kredi Kartı",
    userId: params.uid,
  };
  window.dataLayer.push(eventPayload);
}

export function datalayer_create_card(params) {
  window.dataLayer = window.dataLayer || [];
  let eventPayload = {
    event: "register_card",
    member_status: params.segmentType,
    userId: params.uid,
    balance: 0,
  };
  window.dataLayer.push(eventPayload);
}

export function datalayer_automated_insert_money(params) {
  window.dataLayer = window.dataLayer || [];
  let eventPayload = {
    event: "automated_insert_money",
    minimum_balance: params.minimum_balance,
    selected_balance: params.selected_balance,
    payment_type: "Kredi Kartı",
    userId: params.uid,
  };
  window.dataLayer.push(eventPayload);
}

export function datalayer_register_credit_card(params) {
  try {
    window.dataLayer = window.dataLayer || [];
    let eventPayload = {
      event: "register_credit_card",
      userId: params.uid,
      member_status: params.segmentType,
      balance: params.balance,
    };
    window.dataLayer.push(eventPayload);
  } catch (err) {}
}
